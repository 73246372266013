import styled from "styled-components";
import {
  DESKTOP_1200,
  MOBILE_460,
  TABLET_600,
} from "../../styles/globals/sizes";

export const CarouselContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00000093 0% 0% no-repeat padding-box;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const CarouselContent = styled.div`
  position: relative;
  width: auto;
  max-width: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CarouselImage = styled.div`
  width: 100%;
  height: 80vh;
  object-fit: contain;
  margin-inline: 20px;
  min-height: 50vh;
  @media (max-width: ${DESKTOP_1200}px) {
    min-height: 50vh;
    margin-inline: 10px;
  }
  @media (max-width: ${TABLET_600}px) {
    height: auto;
    width: 100%;
    min-height: 0;
  }
`;

export const ArrowContainer = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 45px;
  width: 45px;
  background: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-inline: 8px;
  cursor: pointer;
  font-size: 2.2rem;
  color: #e02919;
  @media (max-width: ${MOBILE_460}px) {
    height: 35px;
    width: 35px;
  }
`;

export const LeftArrow = styled(ArrowContainer)`
  left: -60px;
  @media (max-width: ${MOBILE_460}px) {
    left: -40px;
  }
`;

export const RightArrow = styled(ArrowContainer)`
  right: -60px;
  @media (max-width: ${MOBILE_460}px) {
    right: -40px;
  }
`;
