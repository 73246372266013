import React, { useState } from "react";
import {
  CarouselContainer,
  CarouselContent,
  CarouselImage,
  LeftArrow,
  RightArrow,
} from "./styles";
import MediaRender from "../Common/MediaRender";

const ImageCarousel = ({ images, currentIndex, onClose }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(currentIndex);

  const handlePrev = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleClose = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <CarouselContainer onClick={handleClose}>
      <CarouselContent onClick={(e) => e.stopPropagation()}>
        <LeftArrow onClick={handlePrev}>‹</LeftArrow>
        <CarouselImage>
          <MediaRender mediaSource={images[currentImageIndex]} />
        </CarouselImage>
        <RightArrow onClick={handleNext}>›</RightArrow>
      </CarouselContent>
    </CarouselContainer>
  );
};

export default ImageCarousel;
