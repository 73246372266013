import styled from "styled-components";
import { TABLET_600, TABLET_740 } from "../../styles/globals/sizes";

export const ShareModalContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background: #00000093 0% 0% no-repeat padding-box;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

export const TopContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 6px;
  @media (max-width: ${TABLET_600}px) {
    padding-left: 9;
  }
`;

export const CloseModal = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #ffffff;
  font-size: 20px;
  &:hover {
    color: #eb4849;
  }
`;

export const ShareModalContent = styled.div`
  width: 608px;
  height: 220px;
  background-color: #404040;
  border-radius: 10px;
  padding: 26px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  @media (max-width: ${TABLET_740}px) {
    width: 90%;
  }
`;

export const ShareModalTitle = styled.h1`
  font-size: 26px;
  margin-bottom: 20px;
  color: #ffffff;
`;

export const ShareModalButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ShareModalButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

export const ShareModalButtonImg = styled.img`
  width: 50px;
  height: 50px;
  @media (max-width: ${TABLET_740}px) {
    width: 40px;
    height: 40px;
  }
`;

export const CopyUrlContaier = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 20px;
  text-overflow: ellipsis;
`;

export const CopyUrl = styled.p`
  height: 48px;
  width: 430px;
  margin-right: 10px;
  padding-left: 23px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  color: #ffffff;
  background: #ffffff5f 0% 0% no-repeat padding-box;
  border: 1px solid #ffffff;
  opacity: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media (max-width: ${TABLET_740}px) {
    width: 100%;
    margin-right: 0;
    margin-left: 8px;
  }
`;

export const CopyButton = styled.button`
  width: 100px;
  height: 40px;
  background: #ffffff;
  border-radius: 23px;
  border: none;
  font-weight: bold;
  @media (max-width: ${TABLET_740}px) {
    display: none;
  }
`;

export const CopyButtonMobile = styled.button`
  display: none;
  @media (max-width: ${TABLET_740}px) {
    font-size: 14px;
    display: block;
    width: 160px;
    margin-left: 15px;
    height: 35px;
    background: #ffffff;
    border-radius: 23px;
    border: none;
    font-weight: bold;
  }
`;

export const CopiedMessage = styled.span`
  height: 30px;
  width: 98px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 13px;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  font-family: Roboto;
  margin-left: 10px;
  background: #ffffff5f;
  @media (max-width: ${TABLET_740}px) {
    display: none;
  }
`;
