import { CONFIG } from "../config/urls";
import { get, post } from "./API";

export const getMemorialMessage = async (id) => {
  try {
    const response = await get(
      `${CONFIG.STRAPI_API_URL}/api/memorial-message/${id}`,
      {
        "Access-Control-Allow-Origin": "*",
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const createMemorialMessage = async (messageData, files) => {
  try {
    const upload = files.map((file) => uploadImageToStrapi(file));
    const responseUploadImages = await Promise.all(upload);
    const imageIds = responseUploadImages.map((resp) => ({ id: resp[0].id }));
    const response = await post(
      `${CONFIG.STRAPI_API_URL}/api/memorial-message`,
      { body: { ...messageData, imageIds } },
      {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        credentials: "include",
      }
    );

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const uploadImageToStrapi = async (imageFile) => {
  const formData = new FormData();
  formData.append("files", imageFile);

  const response = await fetch(`${CONFIG.STRAPI_API_URL}/api/upload`, {
    method: "POST",
    body: formData,
    credentials: "include",
  });

  const result = response.json();
  return result;
};
